import React, {useState} from 'react'
import ChartWidget from './ChartWidget';
import Lottie from 'lottie-react';
import heroAnimation from "../../assets/swap.json";

export default function Tokenomics() {

    const [copy, setCopy] = useState(false);
    
    const address = "5tgdTgiVcAA71Wzh2JCJT5YSWFSFHjwkV2fVHFx3ce87";
    
    const handleCopy = () => {
        navigator.clipboard.writeText(address)
        .then(() => {
        })
        .catch((error) => {
        window.alert('Unable to copy text to clipboard');
    });
        setCopy(true);
        setTimeout(() => {
            setCopy(false);
        }, [2000]);
    }

    return (
        <section id='tokenomics' className='bg-pink pb-10 relative'>
            <div className='max-w-[1000px] mx-auto w-full p-5'>
                <h1 className="monts font-extrabold text-5xl md:text-[3rem] text-center mx-10 md:mx-auto mt-10 mb-10">Tokenomics</h1>
                <div className='flex-center flex-col-reverse md:flex-row-reverse gap-10'>
                    <table className="max-w-[440px] w-full text-sm text-left rtl:text-right text-gray-400">
                            <thead
                                className="bg-ppurple bg-opacity-5 border border-purple border-opacity-50 !rounded-xl text-white">
                                <tr>
                                    <th scope="col" className="pl-4 pr-10 py-3 monts text-sm md:text-[16px] font-light">
                                        <p className="opacity-70 text-sm md:text-lg">Total Supply</p>
                                    </th>
                                    <th scope="col" className="pr-10 py-3 monts text-sm md:text-lg">
                                        <p className="opacity-70 monts font-extrabold">10,000,000</p>
                                    </th>
                                    <th scope="col" className="pr-4 py-3 monts text-sm md:text-lg">
                                        <p className="opacity-70 font-extralight">Percent</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                    <th scope="row" className="px-4 py-4 font-medium whitespace-nowrap text-white">
                                        Presale
                                    </th>
                                    <td className="py-4">
                                        4,580,000
                                    </td>
                                    <td className="py-4">45.8%</td>
                                </tr>
                                <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                    <th scope="row" className="px-4 py-4 font-medium whitespace-nowrap text-white">
                                        Ecosystem
                                    </th>
                                    <td className="py-4">
                                        2,530,000
                                    </td>
                                    <td className="py-4">25.3%</td>
                                </tr>
                                <tr className="odd:bg-[#83a6f30a] even:bg-transparent">
                                    <th scope="row" className="px-4 py-4 font-medium whitespace-nowrap text-white">
                                        Liquidity
                                    </th>
                                    <td className="py-4">
                                        2,890,000
                                    </td>
                                    <td className="py-4">28.9%</td>
                                </tr>
                                <tr
                                    className="text-xs bg-ggreen bg-opacity-5 border-opacity-10 border border-white text-white">
                                    <th scope="col" className="pl-4 pr-10 py-3 monts text-sm md:text-[16px] font-light">
                                        <p className="opacity-70 text-sm">Token Symbol</p>
                                    </th>
                                    <th scope="col" className="py-3 monts text-sm md:text-[16px] font-light">
                                        <p className="opacity-70 text-sm md:text-lg"></p>
                                    </th>
                                    <th scope="col" className="py-3 monts text-sm text-left">
                                        <p className="opacity-70 font-extralight fadetext">SWIFTSWAP</p>
                                    </th>
                                </tr>
                            </tbody>
                    </table>
                    <div>
                        <div className='relative w-[95%] mx-auto max-w-[350px] flex items-center justify-center'>
                            <ChartWidget />
                            <img src="/load.gif" className='h-16 centered-xy' />
                        </div>
                        <div className="flex-center flex-col my-3">
                            <h1 className='monts fadetext'>Contract Address</h1>
                            <div className='flex-center text-center gap-3'>
                                <p className={`dmsans text-gray-400 text-[10px] md:text-xs ${copy && 'scale-105'} contract_add`}>{address}</p>
                                <svg onClick={handleCopy} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className={`w-5 h-5 ${copy ? 'text-[#b890fd]' : 'text-gray-400'} copyBtn cursor-pointer`}>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
