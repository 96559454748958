import React from 'react'

function Header({ connect, isConnected, address }) {
  return (
    <div className='w-full max-w-[1100px] mx-auto m-5 flex items-center justify-between'>
      <div className='w-full max-w-[1050px] mx-auto p-5 flex items-center justify-between'>
            <img src="/logo.png" className='h-10' />
            <div className='nunito text-xs items-center justify-center gap-5 opacity-70 md:flex hidden'>
                <a href="https://www.swiftswap.tech">Home</a>
                <a href="https://www.swiftswap.tech/#tokenomics">Tokenomics</a>
                <a href="https://www.swiftswap.tech/#roadmap">Roadmap</a>
                <a href="https://swiftswap.gitbook.io/swiftswap/">Whitepaper</a>
            </div>
            <div className='flex-center gap-2'>
                <div onClick={connect} className='bg-blue px-4 py-1 rounded-full text-xs cursor-pointer'>
                  {isConnected ? (`${address.slice(0,4)}...${address.slice(38)}`) : 'Connect Wallet' }
                </div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 md:hidden">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                  </svg>
            </div>
        </div>
      {/* <div className='monts items-center justify-center gap-5 text-gray-300 hidden md:flex text-sm'>
        <a href="#">Staking</a>
        <a href="#">Document</a>
        <a href="#">Community</a>
        <a href="#">Roadmap</a>
        <a href="#">Tokenomics</a>
      </div>
      <div className='bg-orange text-xs py-1 px-7 text-bblack rounded-full cursor-pointer' onClick={connect}>
        {isConnected ? (`${address.slice(0,4)}...${address.slice(38)}`) : 'Connect Wallet' }
      </div> */}
    </div>
  )
}

export default Header