import Lottie from 'lottie-react';
import heroAnimation from "../../assets/swap.json";

export default function Hero() {
  return (
    <div className='radialgradfoot min-h-screen relative'>
      <div className='flex-center flex-col py-7 md:pt-14 min-h-[90vh] md:min-h-screen relative'>
        <h1 className='monts text-xl md:text-4xl fadetext font-bold text-center opacity-70 z-20 md:mb-2'>Experience an all in one <br /> intuitive swapping platform.</h1>
        <p className='text-xs md:text-[16px] opacity-50 text-center nunito z-20'>Effortlessly access the best prices across multiple blockchains, <br />execute trades with minimal slippage and seamless swapping</p>
        <div className='text-white flex-center gap-5 monts mt-5'>
          <a href="https://app.swiftswap.tech" className='bluegrad rounded-full md:px-10 px-7 py-1.5 text-sm font-bold'>Launch App</a>
          <a href="https://swiftswap.gitbook.io/swiftswap/" className='border border-white border-opacity-50 md:px-10 px-7 py-1.5 rounded-full text-sm'>Whitepaper</a>
        </div>
        <img src="/hero.gif" className='w-[80%] mx-auto max-w-[350px] mt-10' />
      </div>
      {/* MOVING IMAGES */}
      <div className="w-full mx-auto absolute -bottom-14 md:-bottom-24">
        <div className="backersMobile flex-col">
          <div className="scroll-parent">
            <div className="scroll-element primary">
              {/* ROW 1 */}
              <a href="#" target="_blank">
                <img src='/partners/1.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/2.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/3.png' className="max-w-[180px] md:max-w-[250px] h-[22px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/4.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/5.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/6.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/7.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>

              {/* ROW 2 */}
              <a href="#" target="_blank">
                <img src='/partners/1.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/2.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/3.png' className="max-w-[180px] md:max-w-[250px] h-[22px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/4.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/5.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/6.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              <a href="#" target="_blank">
                <img src='/partners/7.png' className="max-w-[180px] md:max-w-[250px] h-[30px] opacity-70 m-2 md:m-4 object-contain" />
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
