import React, { useEffect } from 'react'

export default function How() {

    useEffect(() => {
        const progressElements = document.querySelectorAll('.progress');
    
        const onHover = (el, steps) => {
          steps.forEach(step => {
            step.classList.remove('current', 'prev');
          });
          el.classList.add('current');
          let prevSibling = el.previousElementSibling;
          while (prevSibling) {
            prevSibling.classList.add('prev');
            prevSibling = prevSibling.previousElementSibling;
          }
        };
    
        progressElements.forEach(progress => {
          const steps = progress.querySelectorAll('div.right > div');
    
          steps.forEach((el) => {
            const hoverHandler = () => onHover(el, steps);
            el.addEventListener('mouseenter', hoverHandler);
            el._hoverHandler = hoverHandler; // Store the handler  for later removal
          });
        });
    
        // Cleanup event listeners on component unmount
        return () => {
          progressElements.forEach(progress => {
            const steps = progress.querySelectorAll('div.right > div');
            steps.forEach((el) => {
              if (el._hoverHandler) {
                el.removeEventListener('mouseenter', el._hoverHandler);
              }
            });
          });
        };
    }, []);

  return (
    <div id='how' className='w-full md:max-w-[850px] mx-auto mt-10 flex flex-col pb-10'>
        <h1 className="monts font-extrabold text-4xl md:text-[3rem] text-center mx-10 md:mx-auto mt-10 md:mb-10">How it Works</h1>
        <div className='flex items-center justify-between flex-col md:flex-row md:gap-16'>
            <div className='space-y-5 md:space-y-5 py-5 relative'>
                <div className="outer">
                    <div className="progress dark">
                        <div className="left">
                            <div></div>
                            <div></div>
                            <div className="current"></div>
                        </div>
                        <div className="right">
                            <div className='flex flex-col items-start pb-5'>
                                <h1 className='textgrads monts text-[14px] text-[#b88fff]'>1. Choose Assets:</h1>
                                <p className='monts text-sm'>Select the assets and amounts you want to trade.</p>
                            </div>
                            <div className='flex flex-col items-start pb-4'>
                                <h1 className='textgrads monts text-[14px] text-[#b88fff]'>2. Choose Networks:</h1>
                                <p className='monts text-sm'> Pick the blockchain networks to use.</p>
                            </div>
                            <div className='flex flex-col items-start pb-7'>
                                <h1 className='monts text-[14px] text-[#b88fff]'>3. Aggregator Scan:</h1>
                                <p className='monts text-sm'>SwiftSwap searches for the best prices across various DEXs.</p>
                            </div>
                            <div className='flex flex-col items-start pb-4'>
                                <h1 className='monts text-[14px] text-[#b88fff]'>4. Optimal Trade:</h1>
                                <p className='monts text-sm'>Execute the trade with minimal slippage.</p>
                            </div>
                            <div className='flex flex-col items-start pb-4'>
                                <h1 className='monts text-[14px] text-[#b88fff]'>5. Transaction Confirmation:</h1>
                                <p className='monts text-sm'>Confirm and monitor your transaction.</p>
                            </div>
                            <div className='done flex flex-col items-start pb-4'>
                                <h1 className='monts text-[14px] text-[#b88fff]'>6. Completed Swap:</h1>
                                <p className='monts text-sm'>Enjoy a seamless swap experience.</p>
                            </div>
                            {/* <div className="done">Done</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <img data-aos="fade-up" data-aos-duration="1500" src="/phones.png" alt="How it works" className='w-[90%] md:w-[350px] object-contain' />
        </div>
    </div>
  )
}
