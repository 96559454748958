import React, { useRef } from 'react'
import Bullet from './Bullet.jsx';

export default function Roadmap() {

    const roadCont = useRef();
    const roadClass = useRef();

    const scrollLeft = () => {
        if (roadCont.current && roadClass.current) {
            roadCont.current.scroll({
                left: roadCont.current.scrollLeft - roadClass.current.offsetWidth * 3,
                behavior: 'smooth'
            });
        }
    };
    
    const scrollRight = () => {
        if (roadCont.current && roadClass.current) {
            roadCont.current.scroll({
                left: roadCont.current.scrollLeft + roadClass.current.offsetWidth * 3,
                behavior: 'smooth'
            });
        }
    };

  return (
    <section id="roadmap" className="w-full relative min-h-[80vh]">
        <div className="flex flex-col w-full max-w-[1050px] mx-auto pt-20 md:py-10 px-7 md:px-0">
            <h1 className="monts font-extrabold text-5xl md:text-[3rem] text-center mx-10 md:mx-auto mt-10 mb-10">Roadmap</h1>

            <div ref={roadCont} className="mt-10 roadCont rounded-lg">

                <div data-aos="fade-up" data-aos-duration="1500"
                    className="flex flex-col items-start gap-5 w-[90%] md:w-[300px]">
                    <div ref={roadClass} className="flex flex-col items-start gap-0 roadClass">
                        <p className="monts opacity-40 text-sm md:text-[16px] rounded-lg">Phase 01</p>
                        <p className="nunito font-extrabold text-sm md:text-xl tracking-wide">Conceptualization and Initial Development</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70">This phase involves analyzing user pain points, studying  competitors platforms, and gathering insights from industry experts.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> User Interface and Experience Design:  Our design team will craft an intuitive and user-friendly interface, catering to both novice and experienced traders.</p>
                    </div>
                </div>
                
                <div data-aos="fade-up" data-aos-duration="1500"
                    className="flex flex-col items-start gap-5 w-[90%] md:w-[300px]">
                    <div ref={roadClass} className="flex flex-col items-start gap-0 roadClass">
                        <p className="monts opacity-40 text-sm md:text-[16px] rounded-lg">Phase 02</p>
                        <p className="nunito font-extrabold text-sm md:text-xl tracking-wide">Development and Alpha Testing</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> Smart Contract Development: Smart contracts will be coded and audited to guarantee security and functionality.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> The platform will undergo rigorous usability testing to ensure seamless navigation and a positive user experience.</p>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1500" className="flex flex-col items-start gap-5 w-[90%] md:w-[300px]">
                    <div ref={roadClass} className="flex flex-col items-start gap-0 roadClass">
                        <p className="monts opacity-40 text-sm md:text-[16px] rounded-lg">Phase 03</p>
                        <p className="nunito font-extrabold text-sm md:text-xl tracking-wide">Beta Launch and Community Engagement:</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> SwiftSwap will be launched in a public beta phase, allowing users to test the platform and provide valuable feedback.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> During this period, we will closely monitor users interactions, gather insights, and make necessary adjustments to enhance the platform.</p>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1500"
                    className="flex flex-col items-start gap-5 w-[90%] md:w-[300px]">
                    <div ref={roadClass} className="flex flex-col items-start gap-0 roadClass">
                        <p className="monts opacity-40 text-sm md:text-[16px] rounded-lg">Phase 04</p>
                        <p className="nunito font-extrabold text-sm md:text-xl tracking-wide">Marketing Campaigns and  Launch:</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> SwiftSwap will be officially launched on pinksale and the platform will be thoroughly tested and audited.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> Comprehensive Marketing Campaigns To attract users and build brand awareness, we will implement a series of marketing campaigns. These will include digital advertising, influencer partnerships, content marketing, and participation in industry events.</p>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1500"
                    className="flex flex-col items-start gap-5 w-[90%] md:w-[300px]">
                    <div ref={roadClass} className="flex flex-col items-start gap-0 roadClass">
                        <p className="monts opacity-40 text-sm md:text-[16px] rounded-lg">Phase 05</p>
                        <p className="nunito font-extrabold text-sm md:text-xl tracking-wide">Feature Expansion and Ecosystem Growth:</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> Introduction of Advanced Trading Features like atoomic swaps, Limit and stop loss order,  flash swaps for  experienced traders seeking sophisticated tools to enhance their trading strategies.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> Expansion of Supported blockchains and Tokens to  ensure that users have access to a diverse array of trading pairs.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70">Partnership Development: Enhance the platform's functionality and introduce services such as fiat on-ramps, lending, and staking. </p>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1500"
                    className="flex flex-col items-start gap-5 w-[90%] md:w-[300px]">
                    <div ref={roadClass} className="flex flex-col items-start gap-0 roadClass">
                        <p className="monts opacity-40 text-sm md:text-[16px] rounded-lg">Phase 06</p>
                        <p className="nunito font-extrabold text-sm md:text-xl tracking-wide"> Mobile app integration</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70">Continuous Innovation and Development</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> Regular updates will be introduced.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> New features and versions with enhanced security measures, and improved user experience incorporating the latest innovations in blockchain and DeFi  .</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70"> Ongoing Community Engagement through regular updates, feedback sessions, and interactive events.</p>
                    </div>
                    <div className="flex items-start gap-3">
                        <Bullet />
                        <p className="nunito text-sm text-left opacity-70">Global Outreach and Continuous Innovation.</p>
                    </div>
                </div>

            </div>

            <div className="arrows hidden md:flex gap-5">
                <svg onClick={scrollLeft} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                    className="arrowLeft h-7 text-white cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                <svg onClick={scrollRight} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                    className="arrowRight h-7 text-white cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
            </div>
        </div>
    </section>
  )
}