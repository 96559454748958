import { BiBookAlt, BiHomeAlt2, BiMoney } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { PiChatCircleBold, PiRoadHorizon } from "react-icons/pi";
import { IoPricetagsOutline } from "react-icons/io5";

export const routes = [
  {
    title: "Home",
    href: "#",
    Icon: BiHomeAlt2,
  },
  {
    title: "Tokenomics",
    href: "#tokenomics",
    Icon: BiMoney,
  },
  {
    title: "Roadmap",
    href: "#roadmap",
    Icon: PiRoadHorizon,
  },
  {
    title: "Whitepaper",
    href: "https://swiftswap.gitbook.io/swiftswap/",
    Icon: BiBookAlt,
  },
];