import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./page/home/index";
import { useEffect } from "react";
import AOS from 'aos';
import { useAccount, useConnect } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import Aggregator from "./page/aggregator";

function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  const { address, isConnected } = useAccount();
    const { connect } = useConnect({
        connector: new MetaMaskConnector(),
  });

  const isAppEndpoint = window.location.host.split('.swiftswap')[0] == 'app';
  return (
  <>
    <Routes>
      <Route path="/" element={
        isAppEndpoint ?
        <Aggregator connect={connect} isConnected={isConnected} address={address} /> :
        <HomePage />
      }
      />
      <Route path="/app" element={
        <Aggregator connect={connect} isConnected={isConnected} address={address} />
      }
      />
    </Routes>
  </>
  );
}

export default App;
