import React from 'react'
import { NavMobile } from './Navmobile'

export default function Navbar({ isNavOpen, setIsNavOpen }) {
  return (
    <nav className='stickyNav'>
        <div className='w-full max-w-[1050px] mx-auto p-5 flex items-center justify-between'>
            <img src="/logo.png" className='h-10' />
            <div className='nunito text-xs items-center justify-center gap-10 opacity-70 md:flex hidden'>
                <a href="#">Home</a>
                <a href="#tokenomics">Tokenomics</a>
                <a href="#roadmap">Roadmap</a>
                <a href="https://swiftswap.gitbook.io/swiftswap/">Whitepaper</a>
            </div>
            <div className='flex-center gap-2'>
                <a href="https://app.swiftswap.tech" className='bg-blue px-4 py-1 rounded-full text-xs'>Launch App</a>
                  <NavMobile isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
            </div>
        </div>
    </nav>
  )
}
