import React from 'react'

export default function Footer() {
    return (
        <div className='md:pt-40 px-4 w-full  max-w-[1000px] mx-auto overflow-x-hidden radialcenter pb-20'>

            <div className='w-full max-w-[1000px] mx-auto flex flex-col'>
                <div className='flex items-center justify-between gap-5 flex-col md:flex-row'>
                    <img src="/load.gif" alt="logo" className="w-32" />
                    <p className='md:hidden text-xs md:text-center text-center opacity-60 max-w-[700px] mx-auto nunito w-full'>Disclaimer: This website is intended to provide information about the products and services available on SwfitSwap. It offers access to all SwfitSwap products and services. Please note that accessing any product or service through the SwfitSwap website is subject to the terms and conditions of SwfitSwap.</p>
                    <a href='mailto:info@swiftswap.tech' className='nunito mb-5 text-sm text-white tracking-wide opacity-90 underline md:hidden'>info@swiftswap.tech</a>
                    <div className='w-[50%] h-[1px] bg-[#89d7fb33] hidden md:block'></div>
                    <div className="flex items-center justify-around md:gap-5 w-full md:w-auto my-5">
                        <a href="https://x.com/SwiftSwap_?t=F0H2vCC7IBAimtkh2Ee3qQ&s=09">
                            <img className="h-8" src="/socials/twitter.png" alt="" />
                        </a>
                        <a href="https://t.me/+9CPGdG7Lnug4MGNk">
                            <img className="h-8" src="/socials/telegram.png" alt="" />
                        </a>
                        <a href="https://swiftswap.gitbook.io/swiftswap/">
                            <img className="h-8" src="/socials/gitbook.png" alt="" />
                        </a>
                        <a href="https://medium.com/@swiftswap7">
                            <img className="h-8" src="/socials/medium.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className='md:flex hidden items-center flex-col justify-center'>
                <h1 className='fadetext text-xl monts'>Disclaimer</h1>
                <p className='mb-2 text-xs md:text-center text-center opacity-60 max-w-[700px] mx-auto nunito w-full'>This website is intended to provide information about the products and services available on SwfitSwap. It offers access to all SwfitSwap products and services. Please note that accessing any product or service through the SwfitSwap website is subject to the terms and conditions of SwfitSwap.</p>
                <a href='mailto:info@swiftswap.tech' className='nunito mb-10 text-sm text-white tracking-wide opacity-90 underline'>info@swiftswap.tech</a>
            </div>
            <div className="flex items-center justify-around md:justify-center md:gap-5 w-full">
                <div className="col-lg-3 col-md-6">
                    <div className="f_widget about-widget pl_70 wow fadeInLeft text-center md:text-left">
                        <ul className="list-unstyled f_list opacity-40 text-sm nunito md:flex gap-5">
                            <li><a href="#tokenomics">Tokenomics</a></li>
                            <li><a href="https://swiftswap.gitbook.io/swiftswap/swiftswap/features">Features</a></li>
                            <li><a href="https://swiftswap.gitbook.io/swiftswap/team">Team</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="f_widget about-widget pl_70 wow fadeInLeft text-center md:text-left">
                        <ul className="list-unstyled f_list opacity-40 text-sm nunito md:flex gap-5">
                            <li><a href="#how">How it Works</a></li>
                            <li><a href="https://swiftswap.gitbook.io/swiftswap/">Document</a></li>
                            <li><a href="#roadmap">Roadmap</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}
