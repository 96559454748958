import React, { useState } from 'react'
import Navbar from './Navbar'
import Hero from './Hero';
import How from './How';
import Tokenomics from './Tokenomics';
import Roadmap from './Roadmap';
import Footer from './Footer';

export default function Homepage() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  
  return (
    <div className='bg-black min-h-screen relative'>
      {isNavOpen && <div className='modal' />}
      <Navbar isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
      <Hero />
      <How />
      <div className='radialgradright min-h-screen'>
        <Tokenomics />
        <Roadmap />
      </div>
      <Footer />
    </div>
  )
}