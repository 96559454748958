import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ChartWidget() {

    const chartOptions = {
        plugins: {
            legend: {
                display: false
            },

            tooltips: {
                enabled: true,
            },

            datalabels: {
                formatter: (value, context) => {
                    const datapoints = context.chart.data.datasets[0].data;
                    function totalSum(total, datapoint) {
                        return total + datapoint;
                    }
                    const totalValue = datapoints.reduce(totalSum, 0);
                    const percentageValue = (value / totalValue * 100).toFixed(1);
                    return `${percentageValue}%`;
                }
            },
        },
        responsive: true,
        animations: {
            tension: {
                duration: 900000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: true
            }
        },
    };
    
    var chartData = {
        labels: ['Presale', 'Ecosystem', 'Liquidity',],      
        datasets: [{
            data: [45.8, 25.3, 28.9],
            backgroundColor: ['#9d65ff', '#fff', '#b88fff'],
            lineTension: 0.1,
            showActualPercentages: true,
            borderColor: "black",
            pointBackgroundColor: "transparent",
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            barPercentage: 0.25,
            categoryPercentage: 0.5,
            hoverOffset: 34,
            datalabels: {
                color: "white",
            },
            options: {
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }
              
        }]
    };

    return (
        <Doughnut data={chartData} options={chartOptions} />
    )
}